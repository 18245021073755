@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Nunito:wght@400;600;700&family=Open+Sans:wght@400;600;700&display=swap");

:root {
  --danger: #da2222;
  --verylight: #f1faee;
  --light: #a8dadc;
  --dark: #457b9d;
  --verydark: #1d3557;
  --gdverydark: linear-gradient(
    to left,
    rgba(36, 59, 85, 1),
    rgba(20, 30, 48, 1)
  );
  --gdlight: linear-gradient(90deg, var(--dark), #0898c4);
  --gdwhite: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.5)
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}

body {
  background: var(--gdverydark);
  font-family: "Nunito", sans-serif; /* All pourpose font, use Monteserrat for titles and other cases. */
  height: 100%;
}

/* Hide all controls on inputs type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
